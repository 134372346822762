.middle-banner-container {
  width: 100%;
  padding: 40px 5%;
  display: flex;
  flex-direction: column;
  background-color:  #f9f9f9;
  gap: 40px;
}

.middle-banner-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.middle-banner-section.reverse {
  flex-direction: row-reverse;
}

.middle-banner-text {
  flex: 1;
  padding: 20px;
}

.banner-heading {
  font-size: 2rem;
  color: #4f416f;
  position: relative;
}

.banner-heading::after {
  content: "";
  width: 0%;
  height: 4px;
  background-color: #4f416f;
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: width 0.5s ease-in-out;
}

.banner-heading:hover::after {
  width: 100%;
}

.middle-banner-text p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

.middle-banner-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-banner-image img {
  width: 100%;
  max-width: 500px;
  height: 335px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  .middle-banner-section {
    flex-direction: column;
  }

  .middle-banner-section.reverse {
    flex-direction: column;
  }
}






/* 
.middle-banner {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.banner-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.banner-section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(79, 65, 111, 0.3), transparent);
  top: 0;
  left: -100%;
  transition: left 0.5s ease-in-out;
}

.banner-section:hover::before {
  left: 0;
}

.banner-section.reverse {
  flex-direction: row-reverse;
}

.text-content {
  flex: 1;
  padding: 20px;
}

.text-content h2 {
  font-size: 28px;
  color: #4f416f;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}

.text-content h2::after {
  content: "";
  width: 100%;
  height: 4px;
  background: #4f416f;
  position: absolute;
  bottom: -5px;
  left: 0;
  transform-origin: left;
  transition: width 0.4s ease-in-out;
}

.text-content h2:hover::after {
  width: 50%;
}

.text-content p {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  transition: transform 0.3s ease-in-out;
}

.banner-section:hover .text-content p {
  transform: translateY(-5px);
}

.image-content {
  flex: 1;
  text-align: center;
}

.image-content img {
  width: 100%;
  max-width: 450px;
  height: auto;
  border-radius: 15px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.banner-section:hover .image-content img {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .banner-section {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .banner-section.reverse {
    flex-direction: column;
  }

  .image-content img {
    max-width: 100%;
    height: auto;
  }
} */
