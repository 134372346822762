/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Training Page Container */
.training-container {
  margin-top: 80px;
}

/* Hero Section */
.hero-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgb(6, 182, 212);
  padding: 25px;
  color: white;
}

.hero-content {
  flex: 1;
  padding: 20px;
}

.hero-content h1 {
  font-size: 2rem;
}

.hero-content p {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.enquiry-btn {
  background-color: purple;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.enquiry-btn:hover {
  background-color: #5d0d5d;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.hero-image img {
  max-width: 100%;
  height: 350px;
}

/* Courses Section */
.courses-section {
  background-color: rgb(146, 204, 230);
  padding: 5rem 0;
  text-align: center;
}

.course-description {
  font-size: 18px;
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto 2rem;
}

.course-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;
  padding: 20px;
}

/* Course Card */
.card {
  width: 220px;
  padding: 1rem;
  background: rgb(252, 251, 251);
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.card-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image img {
  width: 120px;
  height: 100px;
}

.card h1 {
  font-size: 1.2rem;
  margin: 10px 0;
  color: rgb(16, 16, 16);
}

.view-btn {
  padding: 7px;
  border-radius: 20px;
  background-color: rgb(6, 182, 212);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.view-btn:hover {
  background-color: rgb(5, 155, 180);
}

/* Full-Screen Ad */
.full-screen-ad {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 42, 45, 0.66);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  visibility: hidden;
}

.show-ad {
  visibility: visible;
  animation: zoomIn 0.5s ease-in-out forwards;
}

@keyframes zoomIn {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  background-color: red;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
}

.close-btn:hover {
  background-color: darkred;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

}

@media (max-width: 768px) {
  .course-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .enquiry-btn{
    margin-left: 70px;
  }
}

@media (max-width: 480px) {
  .course-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
