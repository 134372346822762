/* 
.skills-container {
  padding: 40px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  overflow-y: auto;
  max-height: 100vh;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}


.skills-container h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #222;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.skills-section {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  padding-bottom: 50px;
  max-height: auto;
}

.skills-category {
  flex: 1 1 350px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 15px;
  padding: 30px;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.skills-category:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.skills-category h3 {
  font-size: 1.8rem;
  color: #0056b3;
  margin-bottom: 15px;
}
.skills-list {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.skill-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  text-align: center;
  transition: transform 0.3s;
}

.skill-item img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: transform 0.3s;
}

.skill-item:hover img {
  transform: scale(1.1);
}

.skill-item p {
  font-size: 16px;
  font-weight: 500;
  color: #222;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 20px;
}

.register {
  padding: 12px 20px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(135deg, #42a5f5, #478ed1);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

.register:hover {
  background: linear-gradient(135deg, #478ed1, #42a5f5);
  transform: scale(1.05);
}

.subject {
  font-size: 22px;
  font-weight: 600;
  color: #444;
  margin-bottom: 15px;
}

html, body {
  overflow-x: hidden;
  height: 100%;
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
  .skills-section {
    flex-direction: column;
    padding-bottom: 60px;
  }

  .skills-category {
    width: 100%;
  }

  .skills-list {
    gap: 15px;
  }

  .skill-item {
    width: 80px;
  }

  .btns {
    flex-direction: column;
    align-items: center;
  }

  .register {
    width: 100%;
    max-width: 250px;
  }
} */
/* General Styles */
/* Skills Component Styles */
.skills-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  /* background: linear-gradient(135deg, #1a1a2e, #16213e); */
  color: #fff;
  background: transparent;
}

.skills-content {
  text-align: center;
  max-width: 1200px;
  width: 100%;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #00aaff;
}

.subject-text {
  font-size: 1.2rem;
  margin-bottom: 30px;
  opacity: 0.9;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  justify-content: center;
}

.skill-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.skill-card:hover {
  transform: translateY(-5px);
}

.skill-category {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffcc00;
}

.skill-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.skill-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 5px;
}

.register-btn-container {
  margin-top: 30px;
}

.register-btn {
  background: #00aaff;
  border: none;
  padding: 12px 25px;
  font-size: 1.2rem;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.register-btn:hover {
  background: #0077cc;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
  }

  .subject-text {
    font-size: 1rem;
    padding: 0 10px;
  }

  .skills-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .skill-card {
    padding: 15px;
  }

  .skill-category {
    font-size: 1.3rem;
  }

  .skill-icon {
    width: 40px;
    height: 40px;
  }

  .register-btn {
    font-size: 1rem;
    padding: 10px 20px;
  }
}
