/* Main Container */
.mid-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    background: #f9f9f9;
    text-align: center;
}

/* Features Section */
.features {
    text-align: center;
    width: 80%;
    max-width: 900px;
}

.features h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 25px;
    color: #222;
}

/* Feature Grid */
.feature-grid {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 20px;
}

/* Feature Box */
.feature-box {
    background: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1;
    min-width: 280px;
    max-width: 320px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.feature-box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
}

/* Feature Icon */
.feature-icon {
    font-size: 50px;
    transition: color 1s ease-in-out; /* Smooth transition */
    margin-bottom: 15px;
}




/* Responsive Design */
@media (max-width: 768px) {
    .feature-grid {
        flex-direction: column;
        align-items: center;
    }

    .feature-box {
        width: 100%;
        max-width: 320px;
    }
}
