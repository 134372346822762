.animation-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(180deg, #53a0ec, #000814); /* Dark gradient */
    z-index: -1;
  }
  
  .bubble {
    position: absolute;
    bottom: -20px;
    width: 10px;
    height: 10px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    animation: rise infinite linear;
  }
  
  @keyframes rise {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100vh);
      opacity: 0;
    }
  }
  