/* Slider Container
.slider {
  width: 100%;
  height: 580px;
  position: relative;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.content {
  position: absolute;
  bottom: 50px;
  left: 20px;
  opacity: 0;
  width: 80%;
  color: #fff;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  animation: slide-up 1s ease 0.5s forwards;
}

@keyframes slide-up {
  0% {
    visibility: visible;
    bottom: 50px;
  }
  100% {
    visibility: visible;
    bottom: 100px;
  }
}

.current .content {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease-in-out;
}


@media screen and (max-width: 768px) {
  .slider {
    height: 400px;
  }
  .content {
    width: 90%;
    font-size: 14px;
    padding: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .slider {
    height: 300px;
  }
  .content {
    width: 95%;
    font-size: 12px;
    padding: 1rem;
  }
} */
.slider {
  width: 100%;
  height: 580px;
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.content {
  position: absolute;
  bottom: 50px;
  left: 20px;
  width: 80%;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.current .content {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (min-width: 768px) {
  .slide img {
    width: 100%;
    height: 100%;
  }
}
