.carrear_img{
    height:400px;
    margin-top: 100px;
}
.sli_img1{
    width:100%;
    height:100%;
}
.sli_img2{
    display:none;
}
.career_p ul{
    list-style-image: url('./img/arrow-58-16.png');
    font-size:19px;
}
.career_p{
    font-family: 'Roboto', serif;
    position: relative;
}
.title2 {
    text-align: center;
    font-size: 28px;
    margin-top: 40px;
    font-weight: bold;
    color: #222;
}

.career_p ul {
    list-style: none;
    padding-left: 0;
}

.img_pro{
    width:80%;
     margin-left:auto;
    margin-right:auto;
    height:300px;
     padding-bottom:20px;
      padding-top:20px;
}
.content_lists{
    width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-top:20px;
    padding-bottom:20px;
    height:auto;
    font-family:'Roboto', serif;
  
   
}
.product_cont{
     width:80%;
    margin-left:auto;
    margin-right:auto;
    padding-top:20px;
    padding-bottom:20px;
    height:auto;
    display:flex;
}
.product_p{
    width:50%;
    height:auto;
    padding-bottom:20px;
}
.cont_para_style{
    width:95%;
     margin-left:auto;
    margin-right:auto;
    height:auto;
     padding-bottom:20px;
    padding-top:20px;
    font-family: 'Roboto', serif;
    font-size:21px;
    text-indent:70px;
    text-align:justify;
  
}
.product_img{
    width:45%;
    margin-right:5%;
    height:100%;
    padding-top:40px;
}
@media screen and (max-width : 762px){
    .carrear_img{
        height:250px;
    }
    .carrear_img .sli_img1{
      display: block;
      margin-left:20px;
      margin-right:20px;
      height:300px;
      width: 450px;
     
     }
    .product_cont{
         width:100%;
        margin-left:auto; 
        margin-right:auto;
        padding-top:20px;
        padding-bottom:20px;
        height:auto;
        display:unset;
    }
    .product_p{
        width:100%;
        height:auto;
        padding-bottom:20px;
    }
    .cont_para_style{
        width:90%;
         margin-left:auto;
        margin-right:auto;
        height:auto;
         padding-bottom:20px;
        padding-top:20px;
        font-family: 'Roboto', serif;
        font-size:18px;
        text-indent:30px;
        text-align:justify;
    }
    .product_img{
        width:100%;
        margin-right:0%;
        height:250px;
    }
    .img1{
        height:100%;
        display: block;
    }
   .img2{
        display:block;
        height:100%;
    }
    .key{
        width:80%;
         margin-left:auto;
        margin-right:auto;
        height:auto;
         padding-bottom:20px;
        padding-top:20px;
        font-family: 'EB Garamond', serif;
        font-size:18px;
        text-indent:30px;
    }
    .carrear_img{
        height:250px;
    }
    
   
    .img_pro{
        width:100%;
         margin-left:auto;
        margin-right:auto;
        height:300px;
         padding-bottom:20px;
        padding-top:20px;
        position: relative;
    }
    .title2{
        text-align:center;
    }
    .side_img{
        width:100%;
        margin-left:auto;
        margin-right:auto;
        height:300px;
        margin-top:20px;
        margin-bottom:20px;
    }
}