/* dropdown design style */
.drop_down_cont {
  width: 350px;
  height: auto;
  padding-bottom: 0px;
  /* border-left: 5px solid #ff635e; */
  background-color: #fff;
  position: absolute;
  z-index: 1;
  margin-top: 28px;
}
.drop_down_cont li {
  font-family: "Josefin Sans", sans-serif;
  font-size: 17px;
  list-style: none;
  margin-left: 10px;
  text-align: left;
  padding: 10px;
}
.drop_down_cont li a {
  text-decoration: none;
  transition: 0.5s;
}
.drop_down_cont1 {
  color: black;
  font-weight: bold;
}
.drop_down_cont1:hover {
  margin-left: 20px;
}
.drop_down_cont .clicked {
  display: none;
}
