/* General Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-clr: #007acc;
  --secondary-clr: #e63946;
  --background-clr: #f7f7f7;
  --text-clr: #333;
  --border-clr: #ddd;
  --hover-clr: #e0e0e0;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  color: var(--text-clr);
  background-color: var(--background-clr);
  line-height: 1.6;
}

/* Mail Container */
.mail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: var(--background-clr);
}

.mail-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  padding: 2rem;
  max-width: 500px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mail-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.mail-title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  color: var(--primary-clr);
  margin-bottom: 1rem;
}

.mail-subtitle {
  font-size: 1rem;
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
}

/* Form Styles */
.mail-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-label {
  font-weight: bold;
  color: var(--text-clr);
}

.form-input {
  padding: 0.75rem;
  border: 2px solid var(--border-clr);
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-input:focus {
  border-color: var(--primary-clr);
  outline: none;
  box-shadow: 0 0 5px rgba(0, 112, 204, 0.3);
}

.submit-button {
  background-color: var(--primary-clr);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-weight: bold;
}

.submit-button:hover {
  background-color: var(--secondary-clr);
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .mail-container {
    padding: 1rem;
  }

  .mail-card {
    padding: 1.5rem;
    max-width: 100%;
  }

  .mail-title {
    font-size: 1.6rem;
  }

  .mail-subtitle {
    font-size: 0.9rem;
  }
}
