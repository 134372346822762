/* General Content Layout */
.content_lists {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
    font-family: 'Roboto', serif;
}

/* Hero Image Section */
.carrear_img {
    height: 400px;
    margin-top: 100px;
}

.sli_img1 {
    width: 100%;
    height: 100%;
}

.sli_img2 {
    display: none;
}

/* Product Section */
.product_cont {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

.product_p {
    width: 50%;
    height: auto;
    padding-bottom: 20px;
}

/* Titles */
.product_title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: #222;
}

/* Paragraph Styling */
.cont_para_style {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    padding-bottom: 20px;
    padding-top: 20px;
    font-family: 'Roboto', serif;
    font-size: 21px;
    text-indent: 50px;
    text-align: justify;
    line-height: 1.6;
}

/* Image Styling */
.img_pro {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    padding-bottom: 20px;
    padding-top: 20px;
}

/* FAQ Section */
.key {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    font-family: 'EB Garamond', serif;
    font-size: 18px;
    text-indent: 30px;
    background: #f9f9f9;
    border-radius: 8px;
}

.key h2 {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #222;
}

.key ul {
    list-style: none;
    padding-left: 0;
    font-size: 18px;
    line-height: 24px;
}

.key ul li {
    margin-bottom: 15px;
}

/* Responsive Design */
@media screen and (max-width: 762px) {
    .product_cont {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        height: auto;
        display: unset;
    }

    .product_p {
        width: 100%;
        height: auto;
        padding-bottom: 20px;
    }

    .cont_para_style {
        width: 90%;
        font-size: 18px;
        text-indent: 30px;
    }

    .product_img {
        width: 100%;
        height: 250px;
    }

    .img_pro {
        width: 100%;
        height: 300px;
    }

    .key {
        width: 90%;
        padding: 15px;
    }

    .key h2 {
        font-size: 24px;
    }
}
