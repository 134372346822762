/* Main container */
.course-container {
  background-color: #f9f9f9;
  padding: 50px 0;
}

/* Section container */
.software-tools {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

/* Section title */
.section-title {
  font-size: 26px;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  padding-bottom: 8px;
}

.section-title::after {
  content: "";
  width: 50px;
  height: 3px;
  background: #ff635e;
  display: block;
  margin: 8px auto;
}

/* Tools grid */
.tools-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

/* Logos */
.img-logo {
  width: 120px;
  height: 120px;
  object-fit: contain;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;
  padding: 10px;
  background: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.img-logo:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .img-logo {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 768px) {
  .img-logo {
    width: 80px;
    height: 80px;
  }

  .section-title {
    font-size: 22px;
  }
}

@media screen and (max-width: 480px) {
  .img-logo {
    width: 70px;
    height: 70px;
  }

  .section-title {
    font-size: 20px;
  }
}
