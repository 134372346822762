.carrear_img {
  height: 400px;
  margin-top: 100px;
}
.main_cont {
  position: relative;
}
.content_lists {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
  font-family: "Roboto", serif;
  position: relative;
}
.product_cont {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
  display: flex;
}
.product_p {
  width: 50%;
  height: auto;
  padding-bottom: 20px;
}
.cont_para_style {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  font-family: "Roboto", serif;
  font-size: 21px;
  text-indent: 70px;
  text-align: justify;
}
.product_img {
  width: 45%;
  margin-right: 5%;
  height: 100%;
  padding-top: 90px;
}

.side_img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width: 762px) {
  .carrear_img {
    height: 300px;
  }
  .product_cont {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
    display: unset;
  }
  .product_p {
    width: 100%;
    height: auto;
    padding-bottom: 20px;
  }
  .cont_para_style {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    padding-bottom: 20px;
    padding-top: 20px;
    font-family: "Roboto", serif;
    font-size: 18px;
    text-indent: 30px;
    text-align: justify;
  }
  .product_img {
    width: 100%;
    margin-right: 0%;
    height: 300px;
  }
  .side_img {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 350px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: aquamarine;
  }
  .img1 {
    height: 100%;
    width: 100%;
    display: block;
  }
  .img2 {
    display: none;
  }
  .title2 {
    text-align: center;
  }
}

