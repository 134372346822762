/* 🌟 General Page Styling */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');

.fullcontrol {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right,#1e3c72, #2a5298);
  color: white;
  text-align: center;
}

/* 📍 Google Map Section */
.contact-page-map-container {
  width: 100%;
  /* max-width: 1100px; */
  margin: 30px auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
}

/* 📞 Contact Info */
.contact-page-container {
  max-width: 1100px;
  margin: 50px auto;
  padding: 40px;
  /* background: rgba(255, 255, 255, 0.15); */
  backdrop-filter: blur(10px);
  /* border-radius: 15px; */
  /* box-shadow: 0px 5px 20px rgba(255, 255, 255, 0.2); */
}

.contact-page-title {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
  color:#23c2cd;
}

.contact-page-list {
  list-style: none;
  padding: 0;
  max-width: 800px;
  font-size: 17px;
  margin-left: 215px;
  margin-top: 50px;
}

.contact-page-list li {
  margin: 15px 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.contact-page-list i {
  color: #ffffff;
  font-size: 22px;
  transition: 0.3s ease-in-out;
}

.contact-page-list i:hover {
  transform: scale(1.1);
  color:  #6be0f2;
}

/* ✉️ Email Link */
.contact-page-email-link {
  color: #c91919;
  font-weight: bold;
  text-decoration: none;
  transition: 0.3s;
}

.contact-page-email-link:hover {
  color: white;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
}

/* 📝 Registration Form */
.contact-page-form-container {
  max-width: 600px;
  margin:  auto;
  padding: 40px;
  /* background: rgba(255, 255, 255, 0.15); */
  /* backdrop-filter: blur(12px); */
  border-radius: 15px;
  /* box-shadow: 0px 6px 25px rgba(255, 255, 255, 0.2); */
  text-align: center;
}

/* Form Title */
.contact-page-form-title {
  font-size: 26px;
  font-weight: 600;
  color: white;
  margin-bottom: 25px;
}

/* Form Inputs */
.contact-page-form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 18px;
  position: relative;
  width: 100%;
}

.contact-page-label {
  font-size: 15px;
  font-weight: bold;
  color: white;
  margin-bottom: 5px;
}

.contact-page-input,
.contact-page-select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.contact-page-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.contact-page-input:focus,
.contact-page-select:focus {
  border-color: #ffca28;
  outline: none;
  box-shadow: 0px 0px 12px rgba(255, 202, 40, 0.7);
}

/* 🚀 Smooth Floating Input Labels */
.contact-page-input:focus + .contact-page-label,
.contact-page-select:focus + .contact-page-label {
  color: #ffca28;
  transform: translateY(-22px);
  font-size: 12px;
  transition: 0.3s ease-in-out;
}

/* 🎨 Submit Button */
.contact-page-submit-button {
  width: 100%;
  background: linear-gradient(135deg, #67bad5, #23c2cd);
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease;
}

.contact-page-submit-button:hover {
  background: linear-gradient(135deg, #67bad5, #23c2cd);
  transform: translateY(-2px);
  box-shadow: 0px 5px 15px rgba(255, 152, 0, 0.5);
}
#phone1{
  margin-left: 3px;
} 

/* 🌐 Responsive Design */
@media (max-width: 768px) {
  .contact-page-container {
    padding: 30px;
  }

  .contact-page-title {
    font-size: 24px;
  }

  .contact-page-form-container {
    padding: 30px;
  }

  .contact-page-input,
  .contact-page-select {
    font-size: 15px;
  }
  .contact-page-list {
    list-style: none;
    padding: 0;
    /* max-width: 200px; */
    font-size: 15px;
    margin-left:0px;
    margin-top: 50px;
  }
  #phone2{
    margin-left: 60px;
  } 
  #phone1{
    margin-left: 80px;
  } 
}
