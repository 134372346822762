/* 🌟 Smooth Gradient Background */
.tie-container {
    width: 100%;
    padding: 60px 5%;
    text-align: center;
    /* background: linear-gradient(135deg, #4f416f, #1e1e2f); */
    color: white;
  }
  
  /* 🎖 Stylish Heading */
  .tie-heading {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
  }
  
  .tie-heading::after {
    content: "";
    width: 50%;
    height: 4px;
    background: #ffcc00;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    transition: width 0.4s ease-in-out;
  }
  
  .tie-heading:hover::after {
    width: 100%;
  }
  
  /* 🏆 Grid Layout */
  .tie-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 25px;
    padding: 20px;
    justify-items: center;
    /* background-color: aliceblue; */
  }
  
  /* 🌟 Card with Glassmorphism Effect */
  .tie-card {
    width: 160px;
    height: 160px;
    background: white;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    box-shadow: 0px 6px 12px rgba(255, 255, 255, 0.1);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    overflow: hidden;
    position: relative;
  }
  
  /* 💡 Hover Glow Effect */
  .tie-card:hover {
    transform: translateY(-8px);
    /* box-shadow: 0px 10px 20px rgba(255, 204, 0, 0.6); */
    /* background: rgba(255, 255, 255, 0.25); */
  }
  
  /* 🖼 Image Styling */
  .tie-card img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
    transition: transform 0.3s ease;
  }
  
  .tie-card:hover img {
    transform: scale(1.1);
  }
  
  /* 📱 Responsive Layout */
  @media screen and (max-width: 768px) {
    .tie-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media screen and (max-width: 480px) {
    .tie-grid {
      grid-template-columns: repeat(1, 1fr);

    }
    .tie-card{
        width: 250px;
        height: 250px;
    }
  }
  