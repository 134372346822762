 /* .carrear_image {
  position: relative;
}
.career_img {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
}
.career_flex {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.career_flex_img {
  width: 24%;
  margin-left: auto;
  margin-right: auto;
  height: 200px;
}
.career_page_cont {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  font-family: "Roboto", serif;
  font-size: 18px;
  text-indent: 30px;
  text-align: justify;
}
.carrear_table_cont {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
}
.carrear_flex {
  width: 80%;
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.liststyle a {
  text-decoration: none;
  color: #ff635e;
}
.content_lists {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
}

.cont_para_style {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  font-family: "Roboto", serif;
  font-size: 21px;
  text-indent: 70px;
  text-align: justify;
}
.career_page_cont {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  font-family: "Roboto", serif;
  font-size: 18px;
  text-indent: 30px;
  text-align: justify;
}
.resumecontainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
}
.resumecontainer h1 {
  text-align: left;
  width: 80%;
  margin: auto;
  padding-bottom: 25px;
}
.resumecontainer form {
  width: 80%;
  margin: auto;
}
.resumecontainer input {
  border: 2px solid #ff635e;
  margin-bottom: 20px;
  border-radius: 4px;
  height: 40px;
}
.resumecontainer .upload {
  background-color: #ff635e;
  color: white;
  border-radius: 4px;
  border: none;
  padding: 5px 20px;
}

@media screen and (max-width: 767px) {
  .carrear_img {
    height: 250px;
  }
  .carrear_img .sli_img1 {
    display: block;
    margin-left: 20px;
    margin-right: 20px;
    height: 300px;
    width: 450px;
  }
  .resumecontainer {
    display: flex;
    flex-direction: column;
  }
  .resumecontainer form {
    padding-left: 5px;
    margin-left: 20px;
  }
  .resumecontainer input {
    border: 2px solid #ff635e;
    margin-bottom: 20px;
    border-radius: 2px;
  }
  .resumecontainer .upload {
    background-color: #ff635e;
    color: white;
  }
}

.fileUploading {
  max-width: 400px;
}

.fileUploading input {
  width: 100%;
  padding: 5px;
}

.carrear_table .quick_links .heading {
  font-size: 20px;
  font-weight: 600;
} 


 */

 /* General Styles */
/* General Styles */
.careers-container {
  font-family: 'Inter', sans-serif; /* Modern font */
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #333;
  background-color: #f8f9fa; /* Light, neutral background */
}

.contents{
  margin: 0;
  color: #0f0e0e; /* Darker text for better contrast */
  font-weight: 600; /* Semi-bold for headings */
}

.p-content{
  color: #555;
  margin: 10px 0;
  font-weight: 400;
  line-height: 1.8;
}

/* Hero Section */
.hero-image {
  width: 100%;
  height: 450px; /* Slightly taller for impact */
  object-fit: cover;
  background-position: center;
  background-size: cover;
  transition: transform 0.5s ease;
  filter: brightness(0.8); /* Subtle dark overlay */
}
/* 
.hero-image:hover {
  transform: scale(1.03);
} */

.join-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
}

.join-team h1 {
  font-size: 3rem; /* Larger for emphasis */
  margin-bottom: 20px;
  color: #434040; /* White text for contrast */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow */
}

.join-team p {
  font-size: 1.25rem;
  max-width: 800px;
  line-height: 1.8;
  color: #141414; /* Light text for contrast */
}

/* Why Work With Us? */
.careers-info {
  padding: 80px 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  margin: 60px auto;
  max-width: 1200px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1); /* Deeper shadow */
  animation: slideIn 1s ease-in-out;
}

.careers-info h2 {
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #1a1a1a;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 40px;
  margin-top: 20px;
}

.benefit-card {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefit-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.benefit-card span {
  font-size: 3rem;
  margin-bottom: 20px;
  display: block;
  color: #007bff;
  transition: color 0.3s ease;
}

.benefit-card:hover span {
  color: #0056b3;
}

.benefit-card h3 {
  font-size: 1.75rem;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.benefit-card p {
  font-size: 1.1rem;
  color: #666;
}

/* Job Listings */
.job-listings {
  padding: 80px 20px;
  text-align: center;
  background-color: #f8f9fa;
  margin: 60px auto;
  max-width: 1200px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  animation: slideIn 1s ease-in-out;
}

.job-listings h2 {
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #1a1a1a;
}

.jobs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-top: 20px;
}

.job-card {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.job-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.job-card h3 {
  font-size: 1.75rem;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.job-card p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 20px;
}

.learn-more-button {
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1rem;
  font-weight: 500;
}

.learn-more-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Internship Section */
.internship-section {
  padding: 80px 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  margin: 60px auto;
  max-width: 1200px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  animation: slideIn 1s ease-in-out;
}

.internship-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #1a1a1a;
}

.internship-section p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  color: #666;
}

/* Resume Upload Form */
.resume-upload {
  padding: 80px 20px;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 12px;
  margin: 60px auto;
  max-width: 1200px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  animation: slideIn 1s ease-in-out;
}

.resume-upload h2 {
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #1a1a1a;
}

.resume-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.resume-form input {
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.resume-form input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 12px rgba(0, 123, 255, 0.3);
}

.resume-form button {
  padding: 14px 28px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.resume-form button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .join-team h1 {
    font-size: 2.5rem;
  }

  .join-team p {
    font-size: 1.1rem;
  }

  .careers-info, .job-listings, .internship-section, .resume-upload {
    padding: 60px 20px;
  }

  .benefits-grid, .jobs-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .join-team h1 {
    font-size: 2rem;
  }

  .join-team p {
    font-size: 1rem;
  }

  .careers-info h2, .job-listings h2, .internship-section h2, .resume-upload h2 {
    font-size: 2rem;
  }

  .benefit-card, .job-card {
    padding: 30px;
  }

  .resume-form input {
    font-size: 0.9rem;
  }

  .resume-form button {
    padding: 12px 24px;
    font-size: 0.9rem;
  }
}