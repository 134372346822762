/* Background Animation */
.fullcontrol {
    background: linear-gradient(45deg, #2b5876, #4e4376);
    background-size: 400% 400%;
    animation: gradientBG 10s ease infinite;
}

@keyframes gradientBG {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

/* Styling for the heading */
.headingtag {
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    margin-top: 100px;
    letter-spacing: 2px;
    position: relative;
    display: inline-block;
    width: 100%;
    font-family: "Poppins", sans-serif;
}

/* Animated underline */
.headingtag::after {
    content: "";
    display: block;
    width: 120px;
    height: 5px;
    background: #ffcc00;
    margin: 10px auto;
    border-radius: 3px;
    animation: underline 1.5s infinite alternate;
}

@keyframes underline {
    from { width: 60px; }
    to { width: 120px; }
}

/* Product Grid Container */
.products-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 25px;
    padding: 40px;
    /* margin-top: 40px; */
    justify-content: center;
    align-items: center;
}

/* Product Card */
.product-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.product-card:hover {
    transform: scale(1.08);
    box-shadow: 0px 8px 22px rgba(255, 255, 255, 0.3);
}

/* Background hover animation */
.product-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    transition: left 0.5s;
}

.product-card:hover::before {
    left: 0;
}

/* Product Image */
.product-image {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
}

.product-card:hover .product-image {
    transform: rotate(5deg) scale(1.1);
}

/* Product Title */
.product-title {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    font-family: "Poppins", sans-serif;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .headingtag {
        font-size: 30px;
    }

    .products-container {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 20px;
    }

    .product-card {
        padding: 15px;
    }

    .product-image {
        width: 100px;
        height: 100px;
    }

    .product-title {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .headingtag {
        font-size: 26px;
    }

    .products-container {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }

    .product-image {
        width: 90px;
        height: 90px;
    }

    .product-title {
        font-size: 14px;
    }
}
